import  Url  from "./models";
import axios from "axios";

export default function SignUp(handler, data) {
  axios({
    method: "post",
    url: Url.addressSignUp,
    data: data,
  }).then((response) => {
    handler(response);
  })
  .catch((error) => {
    handler(error.response);
  });
}
