import Url from "../../../address";

const addressCourierOfferByDeliveryId =
  Url + "/order/delivery/courier_offer/by_delivery_id";
const addressCourierOfferAccept = Url + "/order/delivery/courier_offer/accept";
const addressCourierOfferCreate = Url + "/order/delivery/courier_offer/create";

export default {
  addressCourierOfferByDeliveryId,
  addressCourierOfferAccept,
  addressCourierOfferCreate,
};
