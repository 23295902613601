import React from "react";
import Grid from "hedron";
import { Route, Switch } from "react-router-dom";
import { Responsive } from "../../components";
import License from "./License";
import Contacts from "./Contacts";
import Price from "./Price";

export default class User extends React.Component {
  render() {
    return (
      <>
        <br />
        <Responsive.Breakpoints>
          <Grid.Bounds width="100%">
            <Switch>
              <Route path={"/info/license"} component={License} />
              <Route path={"/info/contacts"} component={Contacts} />
              <Route path={"/info/price"} component={Price} />
            </Switch>
          </Grid.Bounds>
        </Responsive.Breakpoints>
      </>
    );
  }
}
