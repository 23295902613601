import React from "react";
import Grid from "hedron";
import { Responsive } from "../../components";

export default class Contacts extends React.Component {
  render() {
    return (
      <>
        <Grid.Bounds halign="center" height="100%" width="100%" wrap="true">
          <Responsive.CardBoxDefault>
            <div className="uk-card uk-card-default uk-card-body">
              <div class="uk-text-large">Контакты</div>
              <div className="uk-margin">
                ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ИХАЛАЙНЕН" (ООО
                "ИХАЛАЙНЕН") <br/>
                Адрес местонахождения: 198096, Г.САНКТ-ПЕТЕРБУРГ. Внутригородская территория города федерального значения МУНИЦИПАЛЬНЫЙ ОКРУГ АВТОВО, УЛ КРОНШТАДТСКАЯ, Д. 3, К. 4, ЛИТЕРА А, ПОМЕЩ. 5-Н, ОФИС
                1, ОФИС 1В <br />
                ОГРН: 1237800133735 <br />
                Телефон: +7 (931) 266-79-99 <br />
                Адрес электронной почты: ghk9999@bk.ru <br />
              </div>
              <div class="uk-text-large">Реквизиты</div>
              <div className="uk-margin">
                Расчётный счёт: 40702810720000061315 <br />
                Название банка: ООО "Банк Точка" <br />
                БИК: 044525104 <br />
                Корреспондентский счёт: 30101810745374525104 <br />
                Наименование: ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ИХАЛАЙНЕН" <br />
                ИНН: 7805811484 <br />
                КПП: 780501001 <br />
              </div>
              <div className="uk-text-large">Документы</div>
              <div className="uk-margin">
                <Grid.Bounds direction="vertical">
                  <a class="uk-link-text" href="/static/docs/rules.pdf">
                    Правила заполнения накладной
                  </a>
                </Grid.Bounds>
                <Grid.Bounds direction="vertical">
                  <a className="uk-link-text" href="/static/docs/privacy-policy.pdf">
                    Политика конфиденциальности
                  </a>
                </Grid.Bounds>
              </div>
            </div>
          </Responsive.CardBoxDefault>
        </Grid.Bounds>
      </>
    );
  }
}
