import Cookies from 'js-cookie'

// export const Authorization = {
//     isAuthenticated : checkSession()
// };
// export function checkSession() {
//     // const handler = (response) => {
//     //     console.log("checkSession " + response.data.status);
//     //     return response.data.status
//     // };
//     // CheckSession(handler, userIds());
//     // return handler

//     checkSession = CheckSessionSync(userIds());
//     let valid = checkSession.body.status;
//     return valid
// }

export function userIds() {
    return {
        "refracto_user_id": Cookies.get("refracto_user_id"),
        "auth": {
            "session": Cookies.get("session")
        }
    }
};