import Url from "../../address";

const addressDeliveryCreate = Url + "/order/delivery/create";
const addressDeliveryList = Url + "/order/delivery/list";
const addressDeliveryAllInfo = Url + "/order/delivery/allinfo";
const addressDeliveryDelete = Url + "/order/delivery/delete";
const addressDeliveryPick = Url + "/order/delivery/pick";
const addressDeliveryDeliver = Url + "/order/delivery/deliver";
const addressPaymentMethod = Url + "/order/delivery/payment_method";
const addressDeliveryPrice = Url + "/order/delivery/price";
const addressDeliveryTrack = Url + "/order/delivery/track";

export default {
  addressDeliveryCreate,
  addressDeliveryList,
  addressDeliveryAllInfo,
  addressDeliveryDelete,
  addressDeliveryPick,
  addressDeliveryDeliver,
  addressPaymentMethod,
  addressDeliveryPrice,
  addressDeliveryTrack,
};
