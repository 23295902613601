import React from "react";
import { Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

export const Auth = inject("userStore")(
  observer(
    class extends React.Component {
      constructor(props) {
        super(props);
      }
      render() {
        console.log(this.props.userStore.authStore.authorized);
        const route = !this.props.userStore.authStore.authorized ? (
          <Route path={this.props.path} component={this.props.component} />
        ) : (
          <Redirect
            to={{ pathname: "/user", state: { from: this.props.location } }}
          />
        );
        return <>{route}</>;
      }
    },
  ),
);

export const Courier = inject(
  "courierStore",
  "userStore",
)(
  observer(
    class extends React.Component {
      constructor(props) {
        super(props);
      }
      render() {
        const route = this.props.userStore.authStore.isAuthorized ? (
          !this.props.courierStore.checkStore.courier ? (
            <Route path={this.props.path} component={this.props.component} />
          ) : (
            <Redirect
              to={{
                pathname: "/courier",
                state: { from: this.props.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/user/auth",
              state: { from: this.props.location },
            }}
          />
        );
        return <>{route}</>;
      }
    },
  ),
);

export default { Auth, Courier };
