import Url from "./models";
import $ from "jquery";
import Cookies from 'js-cookie'

export default function CheckSession(handler, data) {

  $.ajax({
    url: Url.addressCheckSession,
    method: "post",
    data: {},
    crossDomain: true,

    beforeSend: function (xhr) {
      xhr.setRequestHeader("Session", Cookies.get("session"));
    },

    xhrFields: {
      withCredentials: true,
    },
  })
    .then((response) => {
      console.log(response);
      handler(response);
    })
    .catch((error) => {
      console.log(error.response);
      handler(error.response);
    });
}
