import  Url  from "./models";
import axios from "axios";

export default function Track(handler, data) {
  axios.defaults.withCredentials = true;
  axios({
    method: "post",
    url: Url.addressDeliveryTrack,
    data: data,
  }).then((response) => {
    console.log(response)
    handler(response);
  })
  .catch((error) => {
    console.log(error)
    handler(error.response);
  });
}