import React from "react";
import Grid from "hedron";

export default class License extends React.Component {
    render() {
    return (
      <>
        <Grid.Bounds
          halign="center"
          height="100%"
          width="100%"
          padding="40px 0 0 0"
          margin="-22px 0 0 0 "
          background="#e7eff5"
          wrap="true"
        >
          <Grid.Box>
            <img class="" src="/static/license/litsenziya-1.jpg" />
          </Grid.Box>
        </Grid.Bounds>
      </>
    );
  }
}