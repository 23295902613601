import  Url  from "./models";
import axios from "axios";
import Cookies from "js-cookie";

export default function Accept(handler, data) {
  axios.defaults.withCredentials = true;
  axios({
    method: "post",
    url: Url.addressCourierOfferAccept,
    headers: {
      Session: Cookies.get("session"),
      crossDomain: true,
    },
    data: data,
    withCredentials: true,    
  }).then((response) => {
    handler(response);
  })
  .catch((error) => {
    handler(error.response);
  });
}
