import React from "react";
import Grid from "hedron";
import { Switch, Route } from "react-router-dom";
import { Responsive } from "../../components";
import DeliveryCreate from "./DeliveryCreate";
import OrderList from "./OrderList";
import { ProtectedRoute } from "../../libraries";
import Delivery from "./Delivery"

export default class Order extends React.Component {
  render() {
    return (
      <>
        <br />

        <Responsive.Breakpoints>
          <Grid.Bounds width="100%">
            <Switch>
              <Route
                path={"/order/delivery/track/:delivery_id"}
                component={Delivery.Track}
              />
              <Route
                path={"/order/delivery/track"}
                component={Delivery.Track}
              />
              <Route
                path={"/order/delivery/create"}
                component={DeliveryCreate}
              />
              {/* <ProtectedRoute path={"/order/delivery"} component={Delivery.DeliveryInfo} /> */}
              <ProtectedRoute.Auth path={"/order"} component={OrderList.OrderList} />
            </Switch>
          </Grid.Bounds>
        </Responsive.Breakpoints>
      </>
    );
  }
}
