import React from "react";
import { AddressSuggestions } from "react-dadata";
import "react-dadata/dist/react-dadata.css";

const AddressInput = ({ label, value, onChange, zip, setZip, required }) => {
    return (
        <div className="uk-margin">
            <label className="uk-form-label">{label}</label>
            <div className="uk-form-controls">
                <AddressSuggestions
                    token="ef32aef392cbce29ef2f6d55ced46f5b4890371f"
                    className="uk-input"
                    type="text"
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                    required={required}
                />
            </div>
            <div className="uk-text-normal">Индекс: </div>
            <div className="uk-text-primary">{zip}</div>
        </div>
    );
};

export default AddressInput;
