import { action, computed, decorate, observable } from "mobx";
import { User } from "../../api";
import { userIds } from "../../libraries/actionSession";
import Cookies from "js-cookie";

export default class authStore {
  authorized = Cookies.get("session") !== undefined;

  constructor() {
    this.checkSession();
  }

  get isAuthorized() {
    this.checkSession();
    return this.authorized;
  }

  checkSessionHandler = (response) => {
    this.authorized = response.data.auth;
  };

  checkSession() {
    User.Auth.CheckSession(this.checkSessionHandler, {});
  }

  removeSessionHandler = () => {
    Cookies.remove("session");
    this.authorized = false;
  };

  removeSession() {
    User.Auth.SignOut(this.removeSessionHandler, userIds());
  }
}

decorate(authStore, {
  authorized: observable,
  isAuthorized: computed,
  checkSession: action,
  checkSessionHandler: action,
  removeSession: action,
  removeSessionHandler: action,
});
