import React from "react";
import { Route, Switch } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import Order from "./order/Order";
import Home from "./home/Home";
import Info from "./info";
import MainMenu from "../containers/Menu";
import { Provider } from "mobx-react";
import { UserStore } from "../store";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: 100vh;
    overflow-x: hidden;
    ::-webkit-scrollbar {
       display: none;
    }

  -ms-overflow-style: none; 
  scrollbar-width: none; 

  }
  html {
    max-width: 100%;
    overflow-x: hidden;
    font-size: 15px;
  }
`;

class App extends React.Component {
  render() {
    const userStore = {
      authStore: new UserStore.Auth(),
    };
    return (
      <Provider userStore={userStore}>
        <>

          <GlobalStyle />
          <MainMenu />
          <Switch>
            <Route path={`/order`} component={Order} />
            <Route path={`/info`} component={Info} />
            <Route path={`/`} component={Home} />
          </Switch>
        </>
      </Provider>
    );
  }
}

export default App;
