import React from "react";
import Grid from "hedron";
import { Order } from "../../api";

class OrderInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courier_offers_list: [],
      info: {
        info: { courier: 0, comment: "", weight: 0 },
        sender: { name: "", phone: "", address: "" },
        financial: {
          paid: false,
          payment_method: "cash",
          insurance: 0,
          total: 0,
        },
        destination: {},
      },
      delete: false,
    };
    this.requestOrderDeliveryAllInfo();
    this.requestCourierOffer();
  }

  componentCardBody = (props) => {
    return (
      <div class="uk-card uk-card-default uk-card-body">
        <Grid.Bounds direction="vertical">{props.children}</Grid.Bounds>
      </div>
    );
  };

  handleOrderDelete = (response) => {
    document.location.reload();
  };

  requestOrderDelete = () => {
    Order.Delivery.Delete(this.handleOrderDelete, {
      delivery_id: this.props.delivery_id,
    });
  };

  handlerOrderDeliveryAllInfo = (response) => {
    console.log(response);
    if (response) {
      if (response.data) {
        let info = this.state.info;
        info = response.data;
        console.log(info);
        this.setState({ info: info, orders_exist: true });
      }
    }
  };

  requestOrderDeliveryAllInfo = () => {
    console.log(this.state);
    Order.Delivery.AllInfo(this.handlerOrderDeliveryAllInfo, {
      delivery_id: this.props.delivery_id,
    });
  };

  componentOrderHeader = () => {
    const buttonRemove = !this.state.info.info.courier ? (
      this.state.delete ? (
        <Grid.Bounds direction="vertical">
          <div class="uk-text-primary">Удалить заказ?</div>
          <Grid.Box>
            <button
              class="uk-button-danger"
              onClick={() => {
                this.requestOrderDelete();
              }}
            >
              Удалить
            </button>
            <button
              class="uk-button-primary"
              onClick={() => {
                this.setState({ delete: false });
              }}
            >
              Отменить
            </button>
          </Grid.Box>
        </Grid.Bounds>
      ) : (
        <button
          class="uk-button-danger"
          onClick={() => {
            this.setState({ delete: true });
          }}
        >
          Удалить
        </button>
      )
    ) : undefined;

    return (
      <Grid.Bounds direction="horizontal" valign="center">
        <Grid.Bounds direction="vertical" fill={true}>
          <div class="uk-text-lead">Заказ №{this.props.delivery_id}</div>
          {/* <div class="uk-text-small">Комментарий:</div> */}
          <div class="uk-text-primary"> {this.state.info.info.comment} </div>
        </Grid.Bounds>
        <Grid.Bounds height="100%">{buttonRemove} </Grid.Bounds>
      </Grid.Bounds>
    );
  };

  componentOrderBody = () => {
    return (
      <Grid.Bounds direction="horizontal">
        <Grid.Bounds direction="vertical">
          <Grid.Box>
            <Grid.Bounds direction="horizontal" wrap={true}>
              <div class="uk-text-secondary">{this.state.info.sender.name}</div>
              <Grid.Box width="5px"></Grid.Box>
              <div class="uk-text-secondary">
                (тел. {this.state.info.sender.phone})
              </div>
            </Grid.Bounds>
          </Grid.Box>
          <Grid.Box>
            <Grid.Bounds direction="horizontal" wrap={true}>
              <div class="uk-text-primary">
                {this.state.info.sender.address}
              </div>
              <Grid.Box width="5px"></Grid.Box>
              <div class="uk-text-secondary">
                ({this.state.info.sender.zip})
              </div>
            </Grid.Bounds>
          </Grid.Box>
          <Grid.Box>
            {this.state.info.sender.pickup_date}{" "}
            {this.state.info.sender.pickup_time}
          </Grid.Box>
        </Grid.Bounds>
        <Grid.Box width="5%"></Grid.Box>
        <Grid.Bounds direction="vertical">
          <Grid.Box>
            <Grid.Bounds direction="horizontal" wrap={true}>
              <div class="uk-text-secondary">
                {this.state.info.destination.name}
              </div>
              <Grid.Box width="5px"></Grid.Box>
              <div class="uk-text-secondary">
                (тел. {this.state.info.destination.phone})
              </div>
            </Grid.Bounds>
          </Grid.Box>
          <Grid.Box>
            <Grid.Bounds direction="horizontal" wrap={true}>
              <div class="uk-text-primary">
                {this.state.info.destination.address}
              </div>
              <Grid.Box width="5px"></Grid.Box>
              <div class="uk-text-secondary">
                ({this.state.info.destination.zip})
              </div>
            </Grid.Bounds>
          </Grid.Box>
          <Grid.Box>
            {this.state.info.destination.delivery_date}{" "}
            {this.state.info.destination.delivery_time}
          </Grid.Box>
        </Grid.Bounds>
      </Grid.Bounds>
    );
  };

  componentFinancialInfo = () => {
    const payment_method = {
      cash: "Наличные",
      card: "Карта",
      cryptocurrency: "Криптовалюта",
    };
    return (
      <Grid.Bounds direction="horizontal">
        <Grid.Bounds direction="vertical" fill={true}>
          <div class="uk-text-secondary">
            Вес: {this.state.info.info.weight} кг.
          </div>
          <Grid.Bounds>
            <div class="uk-text-secondary">Способ оплаты:</div>
            <Grid.Box width="10px"></Grid.Box>
            <div class="uk-text-primary">
              {payment_method[this.state.info.financial.payment_method]}
            </div>
          </Grid.Bounds>
        </Grid.Bounds>
        <Grid.Box width="20px"></Grid.Box>

        <Grid.Bounds direction="vertical">
          <Grid.Bounds direction="horizontal">
            <Grid.Bounds direction="vertical" fill={true}>
              <div class="uk-text-small">Стоимость</div>
              <div class="uk-text-lead">
                {this.state.info.financial.price / 100
                  ? this.state.info.financial.price / 100
                  : this.state.info.financial.offer_price}
              </div>
            </Grid.Bounds>
            <Grid.Box margin="18px">
              <div class="uk-text-lead">+</div>
            </Grid.Box>

            <Grid.Bounds direction="vertical" fill={true}>
              <div class="uk-text-small">Страховка</div>
              <div class="uk-text-lead">
                {this.state.info.financial.insurance_price / 100}
              </div>
            </Grid.Bounds>
            <Grid.Box margin="18px">
              <div class="uk-text-lead">=</div>
            </Grid.Box>
            <Grid.Bounds direction="vertical" fill={true}>
              <div class="uk-text-small">Итого</div>
              <div class="uk-text-lead">
                {this.state.info.financial.total
                  ? this.state.info.financial.total / 100
                  : this.state.info.financial.insurance_price / 100 +
                    (this.state.info.financial.price
                      ? this.state.info.financial.price
                      : this.state.info.financial.offer_price)}
              </div>
            </Grid.Bounds>
          </Grid.Bounds>
          {/* <div class="uk-text-small">Комментарий:</div> */}
        </Grid.Bounds>
      </Grid.Bounds>
    );
  };

  componentCourierOffers = () => {
    if (this.state.info.financial.fixed_price) {
      return <></>;
    }
    if (this.state.info.info.courier !== null) {
      if (this.state.info.info.courier !== 0) {
        console.log(this.state.info.info.courier);
        return <></>;
      }
    }

    const header = () => {
      return (
        <Grid.Bounds halign="left">
          <div class="uk-text-bold">Предложения курьеров</div>
          <br />
          <br />
        </Grid.Bounds>
      );
    };
    const offers = () => {
      return (
        <>
          {this.state.courier_offers_list.map((courier_offer, index) => {
            return (
              <>
                <Grid.Bounds fill={true} halign="left" valign="center">
                  <Grid.Bounds direction="vertical">
                    <Grid.Bounds direction="horizontal" wrap={true}>
                      <Grid.Bounds direction="vertical" fill={true}>
                        <div class="uk-text-small">Стоимость курьера</div>
                        <div class="uk-text-lead">
                          {courier_offer.offer_price / 100}
                        </div>
                      </Grid.Bounds>
                      <Grid.Box margin="18px">
                        <div class="uk-text-lead">+</div>
                      </Grid.Box>

                      <Grid.Bounds direction="vertical" fill={true}>
                        <div class="uk-text-small">Страховка</div>
                        <div class="uk-text-lead">
                          {this.state.info.financial.insurance_price / 100}
                        </div>
                      </Grid.Bounds>
                      <Grid.Box margin="18px">
                        <div class="uk-text-lead">=</div>
                      </Grid.Box>
                      <Grid.Bounds direction="vertical" fill={true}>
                        <div class="uk-text-small">Итого</div>
                        <div class="uk-text-lead">
                          {this.state.info.financial.insurance_price / 100 +
                            courier_offer.offer_price / 100}
                        </div>
                      </Grid.Bounds>
                    </Grid.Bounds>
                  </Grid.Bounds>
                </Grid.Bounds>
                <Grid.Bounds halign="right" height="100%">
                  <button
                    class="uk-button uk-button-default uk-button-primary"
                    onClick={() => {
                      this.requestCourierOfferAccept(courier_offer.offer_id);
                    }}
                  >
                    Выбрать курьера
                  </button>
                </Grid.Bounds>
              </>
            );
          })}
        </>
      );
    };
    return (
      <Grid.Bounds direction="vertical">
        {header()}
        <Grid.Bounds valign="center" direction="vertical">
          {offers()}
        </Grid.Bounds>
      </Grid.Bounds>
    );
  };

  componentActionButtons = () => {
    if (this.state.info.financial.total) {
      if (!this.state.info.financial.paid) {
        if (this.state.info.info.courier) {
          if (!this.state.choose_payment_method) {
            return (
              <Grid.Bounds halign="right">
                <button
                  class="uk-button uk-button-default uk-button-primary"
                  onClick={() => {
                    this.setState({ choose_payment_method: true });
                  }}
                >
                  Оплатить
                </button>
              </Grid.Bounds>
            );
          } else if (this.state.choose_payment_method) {
            return (
              <Grid.Bounds halign="right">
                <button
                  class="uk-button uk-button-default uk-button-primary"
                  onClick={() => {
                    Order.Delivery.PaymentMethod(() => {}, {
                      delivery_id: this.props.delivery_id,
                      payment_method: "cash",
                    });
                    this.setState({ choose_payment_method: false });
                  }}
                >
                  Наличными
                </button>
                <button
                  class="uk-button uk-button-default uk-button-primary"
                  onClick={() => {
                    window.open(
                      "https://test.pochtamtbot.com/order/delivery/invoice?delivery_id=" +
                        this.props.delivery_id,
                      "_blank",
                    );
                    this.setState({ choose_payment_method: false });
                  }}
                >
                  По Карте
                </button>
                <button
                  class="uk-button uk-button-default uk-button-primary"
                  onClick={() => {
                    window.open(
                      `https://test.pochtamtbot.com/order/delivery/invoice/cryptocurrency?delivery_id=${this.props.delivery_id}`,
                      "_blank",
                    );
                    this.setState({ choose_payment_method: false });
                  }}
                >
                  Криптовалютой
                </button>
              </Grid.Bounds>
            );
          }
        }
      }
    }
    return <></>;
  };

  handleCourierOffer = (response) => {
    console.log(response);

    if (response) {
      if (response.data) {
        this.setState({ courier_offers_list: response.data });
      }
    }
  };

  requestCourierOffer = () => {
    Order.Delivery.CourierOffer.ByDeliveryId(this.handleCourierOffer, {
      delivery_id: this.props.delivery_id,
    });
  };

  handleCourierOfferAccept = (response) => {
    console.log(response);

    if (response) {
      if (response.data) {
        window.location.reload();
      }
    }
  };

  requestCourierOfferAccept = (offer_id) => {
    Order.Delivery.CourierOffer.Accept(this.handleCourierOfferAccept, {
      delivery_id: this.props.delivery_id,
      offer_id: offer_id,
    });
  };

  render() {
    if (this.state.info) {
      if (this.state.info.sender) {
        if (this.state.info.financial) {
          if (this.state.info.sender) {
            if (this.state.info.destination) {
              return (
                <this.componentCardBody>
                  <this.componentOrderHeader />
                  <Grid.Box height="10px"></Grid.Box>
                  <this.componentOrderBody />
                  <Grid.Box height="20px"></Grid.Box>
                  <this.componentFinancialInfo />
                  <this.componentActionButtons />
                  <this.componentCourierOffers />
                </this.componentCardBody>
              );
            }
          }
        }
      }
    }
    return <></>;
  }
}

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.requestOrderDeliveryList();
  }

  state = { all: [], orders_exist: false };

  handlerOrderDeliveryList = (response) => {
    console.log(response);
    if (response) {
      console.log(response);
      if (response.data) {
        console.log(response.data);
        const all = response.data;
        this.setState({ all: all, orders_exist: true });
      }
    }
  };

  requestOrderDeliveryList() {
    Order.Delivery.List(this.handlerOrderDeliveryList, {});
  }

  render() {
    return <OrderListComponent all={this.state.all} />;
  }
}

class OrderListComponent extends React.Component {
  componentOrdersList = () => {
    return (
      <>
        {this.props.all.map((delivery_id, index) => (
          <Grid.Box margin="10px 0">
            <OrderInfo delivery_id={delivery_id} />
          </Grid.Box>
        ))}
      </>
    );
  };

  componentCreateNewOrder = () => {
    return (
      <button
        class="uk-button uk-button-default uk-button-primary"
        onClick={() => {
          window.location.assign("/order/delivery/create");
        }}
      >
        + СОЗДАТЬ НОВЫЙ ЗАКАЗ
      </button>
    );
  };

  render() {
    return (
      <>
        <Grid.Bounds direction="vertical" halign="center" width="100%">
          <Grid.Box>
            <this.componentCreateNewOrder />
          </Grid.Box>
          <br />

          {this.props.all ? (
            <>
              <Grid.Box>
                <this.componentOrdersList />
              </Grid.Box>
            </>
          ) : (
            <></>
          )}
        </Grid.Bounds>
      </>
    );
  }
}

export default { OrderList };
