import React from "react";
import Grid from "hedron";
import { VectorImage } from "../../components";

export default class Home extends React.Component {
  render() {
    return (
      <>
        <Grid.Bounds
          halign="center"
          height="100%"
          padding="40px 0 0 0"
          background="#e7eff5"
        >
          <Grid.Box>
            <div class="uk-text-primary uk-text-large">
              Доставка с нами это просто и надежно
            </div>
          </Grid.Box>
        </Grid.Bounds>
        <Grid.Bounds
          halign="center"
          valign="center"
          padding="50px 0"
          wrap={true}
          background="#e7eff5"
        >
          <Grid.Box>
            <VectorImage.ManWithMap width="auto" height="300" />
          </Grid.Box>
          <Grid.Bounds
            halign="left"
            height="100%"
            padding="40px"
            direction="vertical"
            background="#e7eff5"
          >
            <Grid.Box>
              <div
                class="uk-text-large uk-text-bold"
                style={{ color: "black" }}
              >
                Удобно
              </div>
            </Grid.Box>
            <Grid.Box>
              <div class="uk-text-secondary" style={{ "font-size": "1.25rem" }}>
                Быстрая система по расчету времени и стоимости доставки
              </div>
            </Grid.Box>
          </Grid.Bounds>
        </Grid.Bounds>
        <Grid.Bounds
          halign="center"
          valign="center"
          padding="40px"
          wrap={true}
          background="#e7eff5"
          dir="rtl"
        >
          <Grid.Box>
            <VectorImage.WomanWithMan width="auto" height="300" />
          </Grid.Box>
          <Grid.Bounds
            halign="right"
            height="100%"
            padding="40px"
            direction="vertical"
            background="#e7eff5"
          >
            <Grid.Box>
              <div
                class="uk-text-large uk-text-bold"
                style={{ color: "black" }}
              >
                Выбор за вами
              </div>
            </Grid.Box>
            <Grid.Box width="100%" dir="ltr">
              <div class="uk-text-secondary" style={{ "font-size": "1.25rem" }}>
                Ихалайнен - это новейшая и уникальная система, в которой
                <br /> Вы сами выбираете: зарегистрироваться как курьер или
                заказать доставку
              </div>
            </Grid.Box>
          </Grid.Bounds>
        </Grid.Bounds>
        <Grid.Bounds
          halign="center"
          valign="center"
          padding="50px 0"
          wrap={true}
          background="#e7eff5"
        >
          <Grid.Box>
            <VectorImage.HappyClient width="auto" height="300" />
          </Grid.Box>
          <Grid.Bounds
            halign="left"
            height="100%"
            padding="40px"
            direction="vertical"
            background="#e7eff5"
          >
            <Grid.Box>
              <div
                class="uk-text-large uk-text-bold"
                style={{ color: "black" }}
              >
                Дополнительный доход
              </div>
            </Grid.Box>
            <Grid.Box>
              <div class="uk-text-secondary" style={{ "font-size": "1.25rem" }}>
                По пути на работу, в учебное заведение или домой, взяв удобный
                заказ, <br /> Вы можете иметь дополнительный заработок
              </div>
            </Grid.Box>
          </Grid.Bounds>
        </Grid.Bounds>
      </>
    );
  }
}
