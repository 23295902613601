import React from "react";
import Grid from "hedron";
import { Responsive } from "../../../components";
import { Order } from "../../../api";

export default class Track extends React.Component {
  render() {
    return (
      <>
        <Grid.Bounds halign="center" height="100%" width="100%" wrap="true">
          <Responsive.CardBoxDefault>
            <div class="uk-card uk-card-default uk-card-body">
              {this.props.match.params.delivery_id ? (
                <TrackResult
                  delivery_id={this.props.match.params.delivery_id}
                />
              ) : (
                <TrackInputID />
              )}
            </div>
          </Responsive.CardBoxDefault>
        </Grid.Bounds>
      </>
    );
  }
}

class TrackResult extends React.Component {
  constructor(props) {
    super(props);
    this.delivery_id = this.props.delivery_id;
    this.state = { info: {} };
    this.requestDeliveryTrack();
  }

  handlerDeliveryTrack = (response) => {
    console.log(response);
    if (response) {
      if (response.data) {
        this.setState({ info: response.data });
      }
    }
  };

  requestDeliveryTrack = () => {
    console.log(this.delivery_id);
    Order.Delivery.Track(this.handlerDeliveryTrack, {
      delivery_id: this.delivery_id,
    });
  };

  render() {
    let statuses = {
      created: "Заказ создан",
      picked: "Отправление передано курьеру",
      delivered: "Отправление доставлено",
      cancelled: "Отменен",
    };
    let orderInfo =
      this.state.info.status === "not_found" ? (
        "Заказ не найден"
      ) : (
        <Grid.Bounds direction="vertical">
          <Grid.Box>Статус: <div class="uk-text-primary">{statuses[this.state.info.status]}</div></Grid.Box>
        </Grid.Bounds>
      );
    return (
      <>
        <TrackInputID />
        <br />
        <br />
        <br />
        <div class="uk-text-large">Заказ №{this.delivery_id}</div>
        {orderInfo}
      </>
    );
  }
}

class TrackInputID extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div class="uk-text-large">Отследить заказ</div>
        <div class="uk-margin">
          <div class="uk-form-controls">
            <input
              class="uk-input"
              type="number"
              name="delivery_id"
              placeholder="Номер заказа"
              min="0"
              step="1"
              onChange={(e) => {
                this.setState({ delivery_id: e.target.value });
              }}
              required
            />
          </div>
        </div>
        <input
          class="uk-button uk-button-primary"
          type="submit"
          value="Поиск"
          onClick={() => {
            if (this.state.delivery_id) {
              window.location.assign(
                "/order/delivery/track/" + this.state.delivery_id,
              );
            }
          }}
        />
      </>
    );
  }
}
