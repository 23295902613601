import Create from "./Create";
import List from "./List";
import AllInfo from "./AllInfo";
import Delete from "./Delete";
import CourierOffer from "./courier_offer";
import Deliver from "./Deliver";
import Pick from "./Pick";
import PaymentMethod from "./PaymentMethod";
import Price from "./Price";
import Track from "./Track";

export default { Create, List, AllInfo, CourierOffer, Delete, Deliver, Pick, PaymentMethod, Price, Track };
