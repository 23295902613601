import React, { useState } from "react";
import Grid from "hedron";
import { Order } from "../../api";
import { useForm } from "react-hook-form";
import AddressInput from "./AddressInput";
import PhoneInput from "./PhoneInput";
import TariffSelect from "./TariffSelect";

function DeliveryCreateForm() {
    const { handleSubmit } = useForm();
    const [formInput, setFormInput] = useState({
        info: { weight: "", tariff: 10, comment: "", email: "" },
        sender: { name: "", phone: "", address: "", zip: "", date: "", time: "" },
        destination: { name: "", phone: "", address: "", zip: "", date: "", time: "" },
    });

    const tariffs = {
        10: {
            "price": 15000,
            "name": "Абонемент 10 доставок в месяц",
        },
        20: {
            "price": 30000,
            "name": "Абонемент 20 доставок в месяц",
        },
        30: {
            "price": 45000,
            "name": "Абонемент 30 доставок в месяц",
        }
    };

    const handleInputChange = (e, section, field) => {
        if (!e) {
           return
        }
        const value = e.target ? e.target.value : e;
        setFormInput((prevInput) => ({
            ...prevInput,
            [section]: {
                ...prevInput[section],
                [field]: value,
            },
        }));
    };

    const onSubmit = async () => {
        try {
            // Prepare data from formInput
            const orderData = {
                comment: formInput.info.comment,
                tariff: formInput.info.tariff,
                email: formInput.info.email,
                sender_name: formInput.sender.name,
                sender_phone: formInput.sender.phone,
                sender_address: formInput.sender.address,
                sender_zip: formInput.sender.zip,
                sender_datetime: formInput.sender.date, // Assuming date and time are in separate fields
                receiver_name: formInput.destination.name,
                receiver_phone: formInput.destination.phone,
                receiver_address: formInput.destination.address,
                receiver_zip: formInput.destination.zip,
                receiver_datetime: formInput.destination.date, // Assuming date and time are in separate fields
            };

            // Make a POST request to the server
            const response = await fetch('https://api.ihaline.ru/create_order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderData),
            });

            if (!response.ok) {
                throw new Error(`Failed to create order: ${response.statusText}`);
            }

            // Handle success response
            const responseData = await response.json();
            console.log("Order created successfully:", responseData);

            // Redirect user to payment link
            window.location.href = responseData.payment_link;

        } catch (error) {
            // Handle error
            console.error("Error creating order:", error);
        }
    };

    return (
        <div className="uk-card uk-card-default uk-card-body uk-align-center">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="uk-text-large">Заказ доставки</div>
                {/* Email Input */}
                <div className="uk-margin">
                    <label className="uk-form-label">Email</label>
                    <div className="uk-form-controls">
                        <input
                            className="uk-input"
                            type="text"
                            placeholder="example@example.com"
                            value={formInput.info.email}
                            onChange={(e) => handleInputChange(e, "info", "email")}
                            required
                        />
                    </div>
                </div>
                {/* Weight Input */}
                <div className="uk-margin">
                    <label className="uk-form-label">Вес (кг)</label>
                    <div className="uk-form-controls">
                        <input
                            className="uk-input"
                            type="number"
                            placeholder="кг"
                            min="0.1"
                            step="0.1"
                            value={formInput.info.weight}
                            onChange={(e) => handleInputChange(e, "info", "weight")}
                            required
                        />
                    </div>
                </div>
                {/* Tariff Select */}
                <TariffSelect value={formInput.info.tariff} onChange={(e) => handleInputChange(e, "info", "tariff")}/>
                {/* Comment Input */}
                <div className="uk-margin">
                    <label className="uk-form-label">Комментарий</label>
                    <div className="uk-form-controls">
                        <input
                            className="uk-input"
                            type="text"
                            placeholder="Комментарий к отправлению"
                            value={formInput.info.comment}
                            onChange={(e) => handleInputChange(e, "info", "comment")}
                            required
                        />
                    </div>
                </div>
                {/* Sender Section */}
                <SenderSection formInput={formInput} onInputChange={handleInputChange}/>
                {/* Destination Section */}
                <DestinationSection formInput={formInput} onInputChange={handleInputChange}/>
                <div>
                    <label>Стоимость тарифа:</label>
                    <span>{" "} {tariffs[formInput.info.tariff].price} руб.</span>
                </div>
                <br/>
                <input className="uk-button uk-button-primary" type="submit" value="Создать заказ"/>
            </form>
        </div>
    );
}

function SenderSection({formInput, onInputChange}) {
    return (
        <>
            <div className="uk-text-large">Отправитель</div>
            <div className="uk-margin">
                {/* Name Input */}
                <label className="uk-form-label">ФИО</label>
                <div className="uk-form-controls">
                    <input
                        className="uk-input"
                        type="text"
                        placeholder="Фамилия, Имя, Отчество"
                        value={formInput.sender.name}
                        onChange={(e) => onInputChange(e, "sender", "name")}
                        required
                    />
                </div>
            </div>
            {/* Phone Input */}
            <PhoneInput
                label="Телефон"
                value={formInput.sender.phone}
                onChange={(e) => onInputChange(e, "sender", "phone")}
                required
            />
            {/* Address Input */}
            <AddressInput
                label="Адрес"
                value={formInput.sender.address}
                onChange={(e) => {
                    console.log(e)
                    onInputChange(e.value, "sender", "address")
                    onInputChange(e.data.postal_code, "sender", "zip")
                }}
                zip={formInput.sender.zip}
                required
            />
            <div className="uk-margin">
                <label className="uk-form-label">Дата отправления</label>
                <div className="uk-form-controls">
                    <input
                        className="uk-input"
                        type="date"
                        value={formInput.sender.date}
                        onChange={(e) => onInputChange(e, "sender", "date")}
                        required
                    />
                </div>
            </div>
        </>
    );
}

function DestinationSection({ formInput, onInputChange }) {
    return (
        <>
            <div className="uk-text-large">Получатель</div>
            <div className="uk-margin">
                {/* Name Input */}
                <label className="uk-form-label">ФИО</label>
                <div className="uk-form-controls">
                    <input
                        className="uk-input"
                        type="text"
                        placeholder="Фамилия, Имя, Отчество"
                        value={formInput.destination.name}
                        onChange={(e) => onInputChange(e, "destination", "name")}
                        required
                    />
                </div>
            </div>
            {/* Phone Input */}
            <PhoneInput
                label="Телефон"
                value={formInput.destination.phone}
                onChange={(e) => onInputChange(e, "destination", "phone")}
                required
            />
            {/* Address Input */}
            <AddressInput
                label="Адрес"
                value={formInput.destination.address}
                onChange={(e) => {
                    console.log(e)
                    onInputChange(e.value, "destination", "address")
                    onInputChange(e.data.postal_code, "destination", "zip")
                }}
                zip={formInput.destination.zip}
                required
            />
            <div className="uk-margin">
                <label className="uk-form-label">Дата доставки</label>
                <div className="uk-form-controls">
                    <input
                        className="uk-input"
                        type="date"
                        value={formInput.destination.date}
                        onChange={(e) => onInputChange(e, "destination", "date")}
                        required
                    />
                </div>
            </div>
        </>
    );
}

export default DeliveryCreateForm;
