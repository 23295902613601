import React from "react";
import { inject, observer } from "mobx-react";
import { decorate } from "mobx";
import { UserStore } from "../store";

export const MainMenu = inject(
  "userStore",
)(
  observer(
    class extends React.Component {
      render() {
        const dasboardMobileMenuCommon = (
            <>
              <li>
                <a class="uk-text-large" href="/order/delivery/create">
                  Заказ доставки
                </a>
              </li>
              <li>
                <a class="uk-text-large" href="/info/license">
                  Лицензии
                </a>
              </li>
              <li>
                <a class="uk-text-large" href="/info/price">
                  Стоимость
                </a>
              </li>
            </>
        );
        const dasboardMobileMenu = (
                <div id="sidenav" uk-offcanvas="flip: true" class="uk-offcanvas">
                  <div class="uk-offcanvas-bar">
                    <ul class="uk-nav">
                      {dasboardMobileMenuCommon}
                      <br />
                      <li>
                        <a class="uk-text-large" href="/info/contacts">
                          Контакты
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
            )
        return (
          <div class="uk-background-primary  uk-section-primary uk-preserve-color">
            <nav
              class="uk-navbar-container uk-navbar-transparent"
              data-uk-navbar="mode: hover"
            >
              <div class="uk-navbar-left">
                <ul
                  class="uk-navbar-nav"
                  style={{
                    paddingLeft: "15px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <li>
                    <a href="/">
                      <img
                        class="uk-logo"
                        width="200"
                        height="Auto"
                        src="/static/logos/post.png"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div class="uk-navbar-center uk-visible@m">
                <ul class="uk-navbar-nav">
                  {/* <li>
                    <a class="uk-text-large" style={{ color: "white" }} href="">
                      Отследить заказ
                    </a>
                  </li> */}
                  <li>
                    <a
                      class="uk-text-large"
                      style={{ color: "white" }}
                      href="/order/delivery/create"
                    >
                      Заказ доставки
                    </a>
                  </li>
                  <li>
                    <a
                      class="uk-text-large"
                      style={{ color: "white" }}
                      href="/info/license"
                    >
                      Лицензии
                    </a>
                  </li>
                    <li>
                        <a
                            className="uk-text-large"
                            style={{ color: "white" }}
                            href="/info/price"
                        >
                            Стоимость
                        </a>
                    </li>
                </ul>
              </div>
              <div class="uk-navbar-right">
                <ul
                  class="uk-navbar-nav uk-visible@m"
                  style={{ paddingRight: "1rem" }}
                >
                  <li>
                    <div className="uk-inline">
                      <a href="/info/contacts">
                        <button
                            className="uk-button uk-button-default"
                            type="button"
                            style={{ color: "white" }}
                        >
                          Контакты
                        </button>
                      </a>
                    </div>
                  </li>
                </ul>
                <a
                  href="#"
                  style={{ "z-index": 99, color: "white" }}
                  class="uk-navbar-toggle uk-hidden@m"
                  uk-navbar-toggle-icon
                  uk-icon="icon: menu"
                  uk-toggle="target: #sidenav"
                ></a>
              </div>
            </nav>
            {dasboardMobileMenu}
          </div>
        );
      }
    },
  ),
);

export default MainMenu;
