import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneInput = ({ value, onChange }) => {
    return (
        <ReactPhoneInput
            onlyCountries={["ru"]}
            country={"ru"}
            inputExtraProps={{
                name: "phone",
                required: true,
                autoFocus: true,
            }}
            specialLabel=" "
            defaultCountry={"ru"}
            value={value}
            inputClass="uk-input"
            onChange={onChange}
        />
    );
};

export default PhoneInput;
