import Grid from "hedron";
import React from "react";

export const Breakpoints = (props) => {
  return (
    <Grid.Provider
      breakpoints={{
        xs: "-400",
        s: "401-600",
        m: "601-1024",
        l: "1025-1280",
        xl: "+1281",
      }}
      padding={"0"}
    >
      {props.children}
    </Grid.Provider>
  );
};
export const CardBoxDefault = (props) => {
  return (
    <Grid.Box
      xs={{ width: "100%" }}
      s={{ width: "90%" }}
      m={{ width: "50%" }}
      l={{ width: "35%" }}
      xl={{ width: "25%" }}
      halign="center"
    >
      {props.children}
    </Grid.Box>
  );
};

export default {Breakpoints, CardBoxDefault}