import Url from "./models";
import Cookies from "js-cookie";
import axios from "axios";

export default function Deliver(handler, data) {
  axios.defaults.withCredentials = true;
  axios({
    method: "post",
    url: Url.addressDeliveryDeliver,
    headers: {
      Session: Cookies.get("session"),
      crossDomain: true,
    },
    data: data,
    withCredentials: true,
  })
    .then((response) => {
      handler(response);
    })
    .catch((error) => {
      handler(error.response);
    });
}
