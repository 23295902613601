import React from "react";
import { Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
// export const ProtectedRoute = ({
//                                    component: Component,
//                                    ...rest
//                                }) => {
//     return (
//         <Route
//             {...rest}
//             render={props => {
//                 if (Authorization.isAuthenticated) {
//                     return <Component {...props} />;
//                 } else {
//                     return (
//                         <Redirect
//                             to={{
//                                 pathname: "/user/auth/signUp",
//                                 state: {
//                                     from: props.location
//                                 }
//                             }}/>
//                     );
//                 }
//             }}
//         />
//     );
// };

export const Auth = inject("userStore")(
  observer(
    class extends React.Component {
      constructor(props) {
        super(props);
      }
      render() {
        console.log(this.props.userStore.authStore.authorized);
        const route = this.props.userStore.authStore.authorized ? (
          <Route path={this.props.path} component={this.props.component} />
        ) : (
          <Redirect
            to={{
              pathname: "/user/auth",
              state: { from: this.props.location },
            }}
          />
        );
        return <>{route}</>;
      }
    },
  ),
);

export const Courier = inject(
  "userStore",
)(
  observer(
    class extends React.Component {
      constructor(props) {
        super(props);
      }

      render() {
        const route = this.props.userStore.authStore.authorized ? (
          this.props.courierStore.checkStore.courier ? (
            <Route path={this.props.path} component={this.props.component} />
          ) : (
            <Redirect
              to={{
                pathname: "/courier/become",
                state: { from: this.props.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/user/auth",
              state: { from: this.props.location },
            }}
          />
        );
        return <>{route}</>;
      }
    },
  ),
);

export default { Auth, Courier };
