import React from "react";

const TariffSelect = ({ value, onChange }) => {
    return (
        <select
            className="uk-select"
            aria-label="Select"
            onChange={onChange}
            value={value}
        >
            <option value={10}>10 доставок в месяц</option>
            <option value={20}>20 доставок в месяц</option>
            <option value={30}>30 доставок в месяц</option>
        </select>
    );
};

export default TariffSelect;
