import React from "react";
import Grid from "hedron";
import { Responsive } from "../../components";

export default class Price extends React.Component {
  render() {
    return (
      <>
        <Grid.Bounds halign="center" height="100%" width="100%" wrap="true">
          <Responsive.CardBoxDefault>
            <div className="uk-card uk-card-default uk-card-body">
              <div class="uk-text-large">Стоимость</div>
              <div className="uk-margin">
                - Абонемент 10 доставок в месяц 15&nbsp;000&nbsp;руб. <br />
                - Абонемент 20 доставок в месяц 30&nbsp;000&nbsp;руб. <br />
                - Абонемент 30 доставок в месяц 45&nbsp;000&nbsp;руб. <br />
              </div>
              <div className="uk-margin">
                Каждая дополнительная доставка – 1&nbsp;500&nbsp;₽ <br />
                Из расчета одна доставка не более 1 кг.<br />
              </div>
            </div>
          </Responsive.CardBoxDefault>
        </Grid.Bounds>
      </>
    );
  }
}
